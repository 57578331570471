import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { useStep } from '@/hooks/wizard/useStep';
import ErrorWrapper from '@/views/ChefsDashboard/Components/Utils/ErrorWrapper';
import ErrorMessage from '@/views/Error/ErrorMessage';
import NavLink from '@/elements/NavLink';

export default function RequestWizardStep() {
    const { meta } = useSelector((state) => state.requestWizard);
    const { activeStep } = useStep();

    const ErrorView = (
        <div className="grow flex justify-center items-center">
            <ErrorMessage
                status="wizard-error"
                cta={
                    <NavLink
                        routeName="chefs"
                        className="btn btn-primary btn-lg">
                        Explore chefs
                    </NavLink>
                }
            />
        </div>
    );

    return (
        <ErrorWrapper
            view={ErrorView}
            message={`Request Wizard Step ${meta?.stepName} Error`}>
            <div className="request-wizard__step">
                <div className="container-lg flex">
                    <div className="flex flex-col grow max-w-full">
                        {/* <RequestWizardProgress /> */}
                        <div className="row grow h-100">
                            <div className="col-12 h-100">
                                <div
                                    className={`request-wizard__step-wrapper mt-5 flex h-100 ${
                                        meta.stepAction == 'back' ||
                                        meta.stepAction == 'jumpBackward'
                                            ? '-reverse'
                                            : ''
                                    }`}>
                                    <SwitchTransition>
                                        <CSSTransition
                                            key={activeStep.name}
                                            nodeRef={activeStep.nodeRef}
                                            timeout={1000}
                                            addEndListener={(done) => {
                                                activeStep.nodeRef.current.addEventListener(
                                                    'transitionend',
                                                    done,
                                                    false
                                                );
                                            }}
                                            classNames={'transition__swipe'}
                                            unmountOnExit>
                                            {(state) => (
                                                <div
                                                    ref={activeStep.nodeRef}
                                                    className={`request-wizard__step-${meta.stepName} grow max-w-full`}>
                                                    <activeStep.component />
                                                </div>
                                            )}
                                        </CSSTransition>
                                    </SwitchTransition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ErrorWrapper>
    );
}
